import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './CheatdayContent.module.scss';
import classNames from 'classnames/bind';
import Image from '../../Image/Image';

const cx = classNames.bind(styles);

const CheatdayContent = () => {
  const data = useStaticQuery(graphql`query CheatdayImages {
            allCloudinaryMedia(sort: {fields: public_id}, filter: {public_id: {regex: "/gallery/cheatday/"}}) {
              edges {
                node {
                  public_id
                  secure_url
                }
              }
            }
        }`);

  const urls = data.allCloudinaryMedia.edges.map((image) => image.node.secure_url);

  function renderFirstGallery(urls) {
    return (
      <div className={cx('cheatDayContent__firstGallery')}>
        <Image url={urls[0]} key={`image-${0}`}/>
        <Image url={urls[1]} key={`image-${1}`}/>
      </div>
    );
  }

  function renderSecondGallery(urls) {
    return (
      <div className={cx('cheatDayContent__secondGallery')}>
        <div className={cx('cheatDayContent__column')}>
          <Image url={urls[2]} key={`image-${2}`}/>
          <Image url={urls[4]} key={`image-${4}`}/>
        </div>
        <div className={cx('cheatDayContent__column')}>
          <Image url={urls[3]} key={`image-${3}`}/>
          <Image url={urls[5]} key={`image-${5}`}/>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.cheatDayContent}>
      {renderFirstGallery(urls)}
      {renderSecondGallery(urls)}
    </div>
  );
};

export default CheatdayContent;