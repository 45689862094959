// @flow strict
import React from 'react';
import ProjectLayout from '../components/ProjectLayout/ProjectLayout';
import CheatdayContent from '../components/ProjectsContent/CheatdayContent/CheatdayContent';

const CheatdayPage = ({location: {pathname}}) => {
  const description = () => (
    <div>
      Visual identity for fast food concept restaurant Cheatday, which specifically caters to extravagant, high calorie
      food as a cheat to an otherwise healthy lifestyle. Logotype variations. Brand colors. Illustrations. Fonts. Menu
      design. Slogans and takeout packaging.
      <br/><br/>
      Bachelor's work.
    </div>
  );

  return (
    <ProjectLayout pathname={pathname} description={description()}>
      <CheatdayContent/>
    </ProjectLayout>
  );
};

export default CheatdayPage;
